import { AutocompletePlugin } from '@algolia/autocomplete-js';
import { h, render } from 'preact';

import { createVoiceSearch, VoiceSearchStatus } from './voiceSearchApi';

type CreateVoiceSearchPluginParams = {
  /**
   * Sets the language of the speech recognition.
   *
   * If not specified, this defaults to the HTML `lang` attribute value, or the user agent's language setting if that isn't set either.
   *
   * @example "en-US"
   */
  language?: string;
};

export function createVoiceSearchPlugin({
  language,
}: CreateVoiceSearchPluginParams): AutocompletePlugin<any, undefined> {
  return {
    subscribe({ setQuery, setIsOpen, refresh }) {
      requestAnimationFrame(() => {
        const inputWrapperSuffix = document.querySelector(
          '.l-options__dropdown'
        );
            
        if (!inputWrapperSuffix) {
          return;
        }

        const input = document.querySelector<HTMLInputElement>('.aa-Input');
        const voiceSearchOverlayContainer = document.createElement('div');
        voiceSearchOverlayContainer.classList.add('aa-VoiceSearchOverlay');

        const voiceSearch = createVoiceSearch({
          language,
          onTranscript(transcript) {
            setQuery(transcript);
            setIsOpen(true);
            refresh();
          },
          onStateChange(state) {
            if (state.status === 'INITIAL') {
              document.body.removeChild(voiceSearchOverlayContainer);
              requestAnimationFrame(() => {
                input.focus();
              });
            } else {
              render(
                <VoiceSearchOverlay
                  status={state.status}
                  transcript={state.transcript}
                  onCancel={voiceSearch.stop}
                />,
                voiceSearchOverlayContainer
              );
            }
          },
        });

        if (!voiceSearch.isBrowserSupported()) {
          return;
        }
        const voiceSearchContainer = document.createElement('div');
        voiceSearchContainer.classList.add('sc-menu-item', 'aa-VoiceSearch');

        render(
          <VoiceSearchButton
            onClick={() => {
              voiceSearch.start();
              document.body.appendChild(voiceSearchOverlayContainer);
            }}
          />,
          voiceSearchContainer
        );

        inputWrapperSuffix.appendChild(voiceSearchContainer);

        window.addEventListener('keydown', (event) => {
          if (
            !document.body.contains(voiceSearchOverlayContainer) ||
            event.key !== 'Escape'
          ) {
            return;
          }

          voiceSearch.stop();
        });
      });
    },
    getSources() {
      return [];
    },
  };
}

function VoiceSearchButton({ onClick }) {
  return (
    <button
      className="aa-VoiceSearchButton"
      title="Búsqueda por voz"
      onClick={(event) => {
        event.preventDefault();
        onClick();
      }}
    >
      <VoiceSearchIcon />
    </button>
  );
}

function VoiceSearchIcon(props: h.JSX.SVGAttributes<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" class="icon" viewBox="0 0 384 512">
        <path class="fa-primary" d="M192 352c53.03 0 96-42.97 96-96v-160c0-53.03-42.97-96-96-96s-96 42.97-96 96v160C96 309 138.1 352 192 352z"/>
        <path class="fa-secondary" d="M368 215.1V256c0 88.91-66.28 162.5-152 174.2V464H256c18.2 0 32.84 15.19 31.96 33.57C287.6 505.8 280.2 512 272 512h-160c-8.222 0-15.57-6.216-15.96-14.43C95.16 479.2 109.8 464 128 464h40v-34.15C79.97 417.7 16 337.8 16 248.1V215.1C16 202.7 26.75 192 40 192S64 202.7 64 215.1L63.1 250.9c0 66.21 53.66 128.6 119.7 132.8C258 388.4 320 329.3 320 256V215.1C320 202.7 330.7 192 344 192S368 202.7 368 215.1z"/>
    </svg>
  );
}

function getStatusText(status: VoiceSearchStatus) {
  switch (status) {
    case 'LISTENING':
      return 'Di el nombre de la planta...';
    case 'RECOGNIZING':
      return 'Reconociendo...';
    case 'REQUESTING_PERMISSION':
      return 'Pidiendo permiso...';
    case 'ERROR':
      return 'Debes aceptar el acceso al micrófono.';
    default:
      return status;
  }
}

function VoiceSearchOverlay({ status, transcript, onCancel }) {
  return (
    <div className="aa-VoiceSearchWrapper">
      <div>{transcript || getStatusText(status)}</div>
      <button
        className="aa-VoiceSearchListeningButton"
        onClick={(event) => {
          event.preventDefault();
          onCancel();
        }}
      >
        <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 384 512">
            <path class="h-fill-green" d="M192 352c53.03 0 96-42.97 96-96h-80C199.2 256 192 248.8 192 240S199.2 224 208 224H288V192h-80C199.2 192 192 184.8 192 176S199.2 160 208 160H288V127.1h-80c-8.836 0-16-7.164-16-16s7.164-16 16-16L288 96c0-53.03-42.97-96-96-96s-96 42.97-96 96v160C96 309 138.1 352 192 352z"/>
            <path class="h-fill-red" d="M208 127.1H288V96l-80 0c-8.836 0-16 7.164-16 16S199.2 127.1 208 127.1zM208 192H288V160h-80C199.2 160 192 167.2 192 176S199.2 192 208 192zM344 192C330.7 192 320 202.7 320 215.1V256c0 73.33-61.97 132.4-136.3 127.7c-66.08-4.168-119.7-66.59-119.7-132.8L64 215.1C64 202.7 53.25 192 40 192S16 202.7 16 215.1v32.15c0 89.66 63.97 169.6 152 181.7V464H128c-18.19 0-32.84 15.19-31.96 33.57C96.43 505.8 103.8 512 112 512h160c8.222 0 15.57-6.216 15.96-14.43C288.8 479.2 274.2 464 256 464h-40v-33.77C301.7 418.5 368 344.9 368 256V215.1C368 202.7 357.3 192 344 192zM208 256H288V224h-80C199.2 224 192 231.2 192 240S199.2 256 208 256z"/>
        </svg>
      </button>
    </div>
  );
}
