
[  
  
  

  
  { 
    "nombre":"Ajo",
    "cientifico":"Allium sativum",
    "slug":"ajo",
    "url":"/plantas/ajo/",
    "familia":"Amarilidáceas",
    "otros-nombres":[""],
    "imagen": "/assets/images/allium-sativum.jpg"
  }
  ,

  

  

  

  

  

  

  

  

  

  

  
  { 
    "nombre":"Capuchina",
    "cientifico":"Tropaeolum majus",
    "slug":"",
    "url":"/plantas/capuchina/",
    "familia":"Tropeoláceas",
    "otros-nombres":["Capuchina","taco de reina","espuela de galán","flor de la sangre","llagas de Cristo","marañuela","mastuerzo de Indias","pelón o texao"],
    "imagen": "/assets/images/tropaeolum-majus.jpg"
  }
  ,

  
  { 
    "nombre":"Cebolla",
    "cientifico":"Allium cepa",
    "slug":"",
    "url":"/plantas/cebolla/",
    "familia":"Amarilidáceas",
    "otros-nombres":[""],
    "imagen": "/assets/images/allium-cepa.jpg"
  }
  ,

  

  

  
  { 
    "nombre":"Espinacas",
    "cientifico":"Spinaca oleracea",
    "slug":"",
    "url":"/plantas/espinaca/",
    "familia":"Quenopodiáceas",
    "otros-nombres":["Espinaca","espinaca común","espinaca de Holanda","espinaca de invierno","espinaca de verano","espinaca hembra","espinaca macho","espinacas"],
    "imagen": "/assets/images/spinaca-oleracea.jpg"
  }
  ,

  

  

  

  
  { 
    "nombre":"Guisante",
    "cientifico":"Pisum sativum",
    "slug":"",
    "url":"/plantas/guisante/",
    "familia":"Leguminosas",
    "otros-nombres":["Arveja","Cometodo","Tirabeque"],
    "imagen": "/assets/images/pisum-sativum.jpg"
  }
  ,

  

  

  

  

  
  { 
    "nombre":"Lechuga",
    "cientifico":"Lactuca sativa",
    "slug":"",
    "url":"/plantas/lechuga/",
    "familia":"Compuestas",
    "otros-nombres":[""],
    "imagen": "/assets/images/lactuca-sativa.jpg"
  }
  ,

  

  

  

  

  

  

  
  { 
    "nombre":"Perejil",
    "cientifico":"Petroselinum crispum",
    "slug":"",
    "url":"/plantas/perejil/",
    "familia":"Apiáceas",
    "otros-nombres":[""],
    "imagen": "/assets/images/petroselinum-crispum.jpg"
  }
  ,

  

  

  

  

  

  

  

  

  
  { 
    "nombre":"Tomate",
    "cientifico":"Solanum lycopersicum",
    "slug":"tomate",
    "url":"/plantas/tomate/",
    "familia":"Solanáceas",
    "otros-nombres":["Jitomate","Tomato","Pomorodo","Tomatea"],
    "imagen": "/assets/images/solanum-lycopersicum.jpg"
  }
  ,

  
  { 
    "nombre":"Zanahoria",
    "cientifico":"Daucus carota",
    "slug":"zanahoria",
    "url":"/plantas/zanahoria/",
    "familia":"Apiáceas",
    "otros-nombres":[""],
    "imagen": "/assets/images/daucus-carota.jpg"
  }
  
  
]