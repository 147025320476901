
[  
   
  { 
    "nombre":"Calabacín",
    "cientifico":"Cucurbita pepo",
    "slug":"",
    "url":"/plantas/calabacin/",
    "familia":"Cucurbitáceas",
    "otros-nombres":["zapallos","calabacines","zapallitos","zapallo italiano","auyamas","ahuyamas","pipianes","ayotes"],
    "imagen": "/assets/images/cucurbita-pepo.jpg"
  }
  ,  
  { 
    "nombre":"Patata",
    "cientifico":"Solanum tuberosum",
    "slug":"patata",
    "url":"/plantas/patata/",
    "familia":"Solanáceas",
    "otros-nombres":[""],
    "imagen": "/assets/images/solanum-tuberosum.jpg"
  }
  ,  
  { 
    "nombre":"Calabaza",
    "cientifico":"Cucurbita maxima",
    "slug":"",
    "url":"/plantas/calabaza/",
    "familia":"Cucurbitáceas",
    "otros-nombres":[""],
    "imagen": "/assets/images/curcubita-maxima.jpg"
  }
  ,  
  { 
    "nombre":"Melón",
    "cientifico":"Cucumis melo",
    "slug":"",
    "url":"/plantas/melon/",
    "familia":"Cucurbitáceas",
    "otros-nombres":[""],
    "imagen": "/assets/images/cucumis-melo.jpg"
  }
  ,  
  { 
    "nombre":"Pepino",
    "cientifico":"Curcumis sativus",
    "slug":"",
    "url":"/plantas/pepino/",
    "familia":"Cucurbitáceas",
    "otros-nombres":[""],
    "imagen": "/assets/images/curcumis-sativus.jpg"
  }
  ,  
  { 
    "nombre":"Sandía",
    "cientifico":"Citrullus lanatus",
    "slug":"",
    "url":"/plantas/sandia/",
    "familia":"Cucurbitáceas",
    "otros-nombres":[""],
    "imagen": "/assets/images/citrullus-lanatus.jpg"
  }
  ,  
  { 
    "nombre":"Pimiento",
    "cientifico":"Capsicum annum",
    "slug":"",
    "url":"/plantas/pimiento/",
    "familia":"Solanáceas",
    "otros-nombres":[""],
    "imagen": "/assets/images/capsicum-annum.jpg"
  }
  ,  
  { 
    "nombre":"Berenjena",
    "cientifico":"Solanum melongena",
    "slug":"",
    "url":"/plantas/berenjena/",
    "familia":"Solanáceas",
    "otros-nombres":[""],
    "imagen": "/assets/images/solanum-melongena.jpg"
  }
  ,  
  { 
    "nombre":"Lechuga",
    "cientifico":"Lactuca sativa",
    "slug":"",
    "url":"/plantas/lechuga/",
    "familia":"Compuestas",
    "otros-nombres":[""],
    "imagen": "/assets/images/lactuca-sativa.jpg"
  }
  ,  
  { 
    "nombre":"Girasol",
    "cientifico":"Helianthus annuus",
    "slug":"",
    "url":"/plantas/girasol/",
    "familia":"Compuestas",
    "otros-nombres":[""],
    "imagen": "/assets/images/helianthus-annuus.jpg"
  }
  ,  
  { 
    "nombre":"Alcachofa",
    "cientifico":"Cynara scolymus",
    "slug":"",
    "url":"/plantas/alcachofa/",
    "familia":"Compuestas",
    "otros-nombres":[""],
    "imagen": "/assets/images/cynara-scolymus.jpg"
  }
  ,  
  { 
    "nombre":"Tomate",
    "cientifico":"Solanum lycopersicum",
    "slug":"tomate",
    "url":"/plantas/tomate/",
    "familia":"Solanáceas",
    "otros-nombres":["Jitomate","Tomato","Pomorodo","Tomatea"],
    "imagen": "/assets/images/solanum-lycopersicum.jpg"
  }
  ,  
  { 
    "nombre":"Ajo",
    "cientifico":"Allium sativum",
    "slug":"ajo",
    "url":"/plantas/ajo/",
    "familia":"Amarilidáceas",
    "otros-nombres":[""],
    "imagen": "/assets/images/allium-sativum.jpg"
  }
  ,  
  { 
    "nombre":"Rabanito",
    "cientifico":"Raphanus sativa",
    "slug":"",
    "url":"/plantas/rabanito/",
    "familia":"Crucíferas",
    "otros-nombres":[""],
    "imagen": "/assets/images/raphanus-sativa.jpg"
  }
  ,  
  { 
    "nombre":"Repollo",
    "cientifico":"Brassica oleracea",
    "slug":"",
    "url":"/plantas/repollo/",
    "familia":"Crucíferas",
    "otros-nombres":[""],
    "imagen": "/assets/images/brassica-oleracea.jpg"
  }
  ,  
  { 
    "nombre":"Lombarda",
    "cientifico":"Brassica oleracea var. capitata f. rubra",
    "slug":"",
    "url":"/plantas/lombarda/",
    "familia":"Crucíferas",
    "otros-nombres":[""],
    "imagen": "/assets/images/brassica-oleracea-var.-capitata-f.-rubra.jpg"
  }
  ,  
  { 
    "nombre":"Coliflor",
    "cientifico":"Brassica oleracea var. botrytis",
    "slug":"",
    "url":"/plantas/coliflor/",
    "familia":"Crucíferas",
    "otros-nombres":[""],
    "imagen": "/assets/images/brassica-oleracea-var.-botrytis.jpg"
  }
  ,  
  { 
    "nombre":"Nabo",
    "cientifico":" Brassica rapa",
    "slug":"",
    "url":"/plantas/nabo/",
    "familia":"Crucíferas",
    "otros-nombres":[""],
    "imagen": "/assets/images/brassica-rapa.jpg"
  }
  ,  
  { 
    "nombre":"Guisante",
    "cientifico":"Pisum sativum",
    "slug":"",
    "url":"/plantas/guisante/",
    "familia":"Leguminosas",
    "otros-nombres":["Arveja","Cometodo","Tirabeque"],
    "imagen": "/assets/images/pisum-sativum.jpg"
  }
  ,  
  { 
    "nombre":"Haba",
    "cientifico":"Vicia faba",
    "slug":"",
    "url":"/plantas/haba/",
    "familia":"Leguminosas",
    "otros-nombres":[""],
    "imagen": "/assets/images/vicia-faba.jpg"
  }
  ,  
  { 
    "nombre":"Judía",
    "cientifico":"Phaseolus vulgaris",
    "slug":"",
    "url":"/plantas/judia/",
    "familia":"Leguminosas",
    "otros-nombres":[""],
    "imagen": "/assets/images/phaseolus-vulgaris.jpg"
  }
  ,  
  { 
    "nombre":"Espinacas",
    "cientifico":"Spinaca oleracea",
    "slug":"",
    "url":"/plantas/espinaca/",
    "familia":"Quenopodiáceas",
    "otros-nombres":["Espinaca","espinaca común","espinaca de Holanda","espinaca de invierno","espinaca de verano","espinaca hembra","espinaca macho","espinacas"],
    "imagen": "/assets/images/spinaca-oleracea.jpg"
  }
  ,  
  { 
    "nombre":"Acelga",
    "cientifico":"Beta vulgaris var. cicla",
    "slug":"",
    "url":"/plantas/acelga/",
    "familia":"Quenopodiáceas",
    "otros-nombres":[""],
    "imagen": "/assets/images/beta-vulgaris-var.-cicla.jpg"
  }
  ,  
  { 
    "nombre":"Remolacha",
    "cientifico":"Beta vulgaris var. rapa",
    "slug":"",
    "url":"/plantas/remolacha/",
    "familia":"Quenopodiáceas",
    "otros-nombres":[""],
    "imagen": "/assets/images/beta-vulgaris-var.-rapa.jpg"
  }
  ,  
  { 
    "nombre":"Cebolla",
    "cientifico":"Allium cepa",
    "slug":"",
    "url":"/plantas/cebolla/",
    "familia":"Amarilidáceas",
    "otros-nombres":[""],
    "imagen": "/assets/images/allium-cepa.jpg"
  }
  ,  
  { 
    "nombre":"Puerro",
    "cientifico":"Allium ampeloprasum var. porrum",
    "slug":"",
    "url":"/plantas/puerro/",
    "familia":"Amarilidáceas",
    "otros-nombres":["Ajoporro","porro","ajo porro","ajo puerro","poro","cebolla larga"],
    "imagen": "/assets/images/allium-porrum.jpg"
  }
  ,  
  { 
    "nombre":"Zanahoria",
    "cientifico":"Daucus carota",
    "slug":"zanahoria",
    "url":"/plantas/zanahoria/",
    "familia":"Apiáceas",
    "otros-nombres":[""],
    "imagen": "/assets/images/daucus-carota.jpg"
  }
  ,  
  { 
    "nombre":"Maíz",
    "cientifico":"Zea mays",
    "slug":"",
    "url":"/plantas/maiz/",
    "familia":"Gramíneas",
    "otros-nombres":[""],
    "imagen": "/assets/images/zea-mays.jpg"
  }
  ,  
  { 
    "nombre":"Capuchina",
    "cientifico":"Tropaeolum majus",
    "slug":"",
    "url":"/plantas/capuchina/",
    "familia":"Tropeoláceas",
    "otros-nombres":["Capuchina","taco de reina","espuela de galán","flor de la sangre","llagas de Cristo","marañuela","mastuerzo de Indias","pelón o texao"],
    "imagen": "/assets/images/tropaeolum-majus.jpg"
  }
  ,  
  { 
    "nombre":"Perejil",
    "cientifico":"Petroselinum crispum",
    "slug":"",
    "url":"/plantas/perejil/",
    "familia":"Apiáceas",
    "otros-nombres":[""],
    "imagen": "/assets/images/petroselinum-crispum.jpg"
  }
  ,  
  { 
    "nombre":"Lavanda",
    "cientifico":"Lavandula angustifolia",
    "slug":"",
    "url":"/plantas/lavanda/",
    "familia":"Lamiáceas",
    "otros-nombres":[""],
    "imagen": "/assets/images/lavandula-angustifolia.jpg"
  }
  ,  
  { 
    "nombre":"Tagete",
    "cientifico":"Tagetes patula",
    "slug":"",
    "url":"/plantas/tagete/",
    "familia":"Compuestas",
    "otros-nombres":["Clavel de moro","damasquina","clavel turco","clavel de Indias"],
    "imagen": "/assets/images/tagetes-patula.jpg"
  }
  ,  
  { 
    "nombre":"Apio",
    "cientifico":"Apium graveolens var. graveolens",
    "slug":"",
    "url":"/plantas/apio/",
    "familia":"Apiáceas",
    "otros-nombres":[""],
    "imagen": "/assets/images/apium-graveolens-var-graveolens.jpg"
  }
  ,  
  { 
    "nombre":"Fresa",
    "cientifico":"Fragaria × ananassa",
    "slug":"",
    "url":"/plantas/fresa/",
    "familia":"Rosáceas",
    "otros-nombres":["Frutilla","fragaria"],
    "imagen": "/assets/images/fragaria-ananassa.jpg"
  }
  ,  
  { 
    "nombre":"Batata",
    "cientifico":"Ipomoea batatas",
    "slug":"",
    "url":"/plantas/batata/",
    "familia":"Convolvuláceas",
    "otros-nombres":["papa dulce","patata dulce","camote","moniato","boniato"],
    "imagen": "/assets/images/ipomoea-batatas.jpg"
  }
  ,  
  { 
    "nombre":"Brócoli",
    "cientifico":"Brassica oleracea var. italica",
    "slug":"",
    "url":"/plantas/brocoli/",
    "familia":"",
    "otros-nombres":[""],
    "imagen": "/assets/images/brassica-oleracea-var-italica.jpg"
  }
  ,  
  { 
    "nombre":"Escarola",
    "cientifico":"Cichorium endivia",
    "slug":"",
    "url":"/plantas/escarola/",
    "familia":"Compuestas",
    "otros-nombres":["Endivia","achicoria"],
    "imagen": "/assets/images/cichorium-endivia.jpg"
  }
  ,  
  { 
    "nombre":"Espárrago",
    "cientifico":"Asparagus officinalis",
    "slug":"",
    "url":"/plantas/esparrago/",
    "familia":"",
    "otros-nombres":["Esparraguera"],
    "imagen": "/assets/images/asparagus-officinalis.jpg"
  }
  ,  
  { 
    "nombre":"Hinojo",
    "cientifico":"Foeniculum vulgare",
    "slug":"",
    "url":"/plantas/hinojo/",
    "familia":"Apiáceas",
    "otros-nombres":[""],
    "imagen": "/assets/images/foeniculum-vulgare.jpg"
  }
  ,  
  { 
    "nombre":"Rúcula",
    "cientifico":"Eruca vesicaria",
    "slug":"",
    "url":"/plantas/rucula/",
    "familia":"",
    "otros-nombres":[""],
    "imagen": "/assets/images/eruca-vesicaria.jpg"
  }
  ,  
  { 
    "nombre":"Borraja",
    "cientifico":"Borago officinalis",
    "slug":"",
    "url":"/plantas/borraja/",
    "familia":"",
    "otros-nombres":[""],
    "imagen": "/assets/images/borago-officinalis.jpg"
  }
  ,  
  { 
    "nombre":"Caléndula",
    "cientifico":"Calendula officinalis",
    "slug":"",
    "url":"/plantas/calendula/",
    "familia":"Compuestas",
    "otros-nombres":[""],
    "imagen": "/assets/images/calendula-officinalis.jpg"
  }
  ,  
  { 
    "nombre":"Albahaca",
    "cientifico":"Ocimum basilicum",
    "slug":"",
    "url":"/plantas/albahaca/",
    "familia":"Lamiáceas",
    "otros-nombres":["Alhábega","basílico"],
    "imagen": "/assets/images/ocimum-basilicum.jpg"
  }
     
]